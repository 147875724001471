import axios from 'axios';
import {useContext} from "react";
import {UserContext} from "../contexts/UserContext";

const authenticatedInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_URL}/api`,
    headers: {
        'Access-Control-Allow-Origin' : '*',
    }
});

authenticatedInstance.interceptors.request.use(config => {
    const token = localStorage.getItem('JWT');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

authenticatedInstance.interceptors.response.use(
    response => response, // Pass through if the response is successful
    error => {
        if (error.response && error.response.status === 401) {
            // Handle unauthorized errors
            const { logout } = useContext(UserContext);
            logout(); // Log the user out using the context method
            window.location.href = '/login'; // Redirect to the login page
        }
        return Promise.reject(error); // Pass the error for further handling
    }
);
export default authenticatedInstance;
