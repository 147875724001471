import React, {useState, useEffect, useRef} from 'react';
import { Stomp } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import { jwtDecode } from 'jwt-decode';
import authenticatedInstance from "../../custom-axios/axios-auth";
import ReactModal from 'react-modal';
// import './ChatPage.css';
import sanitizeHtml from 'sanitize-html';
import ContentEditable from 'react-contenteditable';
import MessageList from "./MessageList";
import { FaPaperPlane, FaPaperclip, FaTimes } from 'react-icons/fa';
import ReactGA from "react-ga4";
import Avatar from "@mui/material/Avatar";
ReactModal.setAppElement('#root');

const ChatPage = ({ conversation, draftMessage, onSaveDraft, onlineStatus  }) => {
    const [message, setMessage] = useState(conversation.prefilledMessage || '');
    const [isFormattedMessage, setIsFormattedMessage] = useState(!!conversation.prefilledMessage);
    const [file, setFile] = useState(null);
    const [stompClient, setStompClient] = useState(null);
    const [userId, setUserId] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState({ type: '', url: '' });
    const [showWarning, setShowWarning] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editText, setEditText] = useState('');
    const [editUrl, setEditUrl] = useState('');
    const [messageBeforeEdit, setMessageBeforeEdit] = useState(conversation.prefilledMessage || '');
    const [orderDetails, setOrderDetails] = useState(null);
    const [filePreview, setFilePreview] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploadError, setUploadError] = useState(null);
    const [currentUser, setCurrentUser] = useState(null); // For current user information
    const [chatUser, setChatUser] = useState(null); // For the person we are chatting with

    const BASE_FILE_URL = `${process.env.REACT_APP_BACKEND_URL}/api/files/download`;

    const chatAreaRef = useRef(null);
    const chatMessagesRef = useRef(null);
    const subscriptionsRef = useRef([]);
    const fileInputRef = useRef(null);
    const sendButtonRef = useRef(null);



    useEffect(() => {
        sendButtonRef.current = document.getElementById('sendMessageButton');
    }, []);


    useEffect(() => {
        const token = localStorage.getItem('JWT');
        if (token) {
            const decoded = jwtDecode(token);
            const currentUserId = String(decoded.userId);
            setUserId(currentUserId);



            if (conversation) {
                setMessage(draftMessage || conversation.prefilledMessage || '');
                setOrderDetails(conversation.orderDetails || {});  // Set orderDetails from conversation

            }


            const socket = new SockJS(`${process.env.REACT_APP_BACKEND_URL}/ws`);
            // const socket = new SockJS(`http://ws.thehomemadeshop.com/ws`);
            const client = Stomp.over(socket);
            setStompClient(client);

            client.connect({}, () => {
                // Unsubscribe old subscriptions if they exist
                subscriptionsRef.current.forEach(sub => sub.unsubscribe());
                subscriptionsRef.current = [];
            });

            return () => {
                if (stompClient) {
                    stompClient.disconnect();
                }
            };
        }
    }, [conversation]);

    // Fetch and set the current user info
    useEffect(() => {
        const fetchCurrentUser = async () => {
            const token = localStorage.getItem('JWT'); // Assuming the token is stored in localStorage
            if (token) {
                const decoded = jwtDecode(token);
                if (!decoded.userId) {
                    console.error('userId not found in the decoded token');
                    return;
                }
                const currentUserId = String(decoded.userId);

                try {
                    const response = await authenticatedInstance.get(`/user-information/${currentUserId}`);
                    setCurrentUser(response.data);
                } catch (error) {
                    console.error('Error fetching current user information:', error);
                }
            }

        };

        fetchCurrentUser();
    }, [conversation]); // Runs once on component mount

// Fetch and set the chat user info whenever the conversation changes
    useEffect(() => {
        const fetchChatUser = async () => {
            if (conversation && conversation.userId) {
                try {
                    const response = await authenticatedInstance.get(`/user-information/${conversation.userId}`);
                    setChatUser(response.data);
                } catch (error) {
                    console.error('Error fetching chat user information:', error);
                }
            }
        };

        fetchChatUser();
    }, [conversation]); // Runs whenever the conversation changes // Trigger this effect when the conversation changes


    const sendMessage = async () => {
        // Reset the error message when sending a new message
        setUploadError(null);


        const currentMessage = message.trim();

        if (file) {
            try {
                if (currentMessage) {
                    sendTextMessage(currentMessage);
                } else {
                }
                await sendFileMessage();
            } catch (error) {
                console.error('Error sending file message:', error);
            }
        } else if (currentMessage) {
            sendTextMessage(currentMessage);
        } else {
        }
    };

    const sendPushNotification = async (userIds: string) => {
        const payload = {
            userId: userIds,
            title: "New Message",
            body: "You have new message!"
        };
        try {
            const response = await authenticatedInstance.post('/notifications/send-push', payload);
            console.log('Notification sent successfully:', response.data);
            console.log("RECIVER ID"+ userIds);
        } catch (error) {
            console.error('Failed to send notification:', error.response?.data || error.message);
        }
    };
    const sendOrderPushNotification = async (userIds: string) => {
        const payload = {
            userId: userIds,
            title: "New Order",
            body: "You have new order!"
        };
        try {
            const response = await authenticatedInstance.post('/notifications/send-push', payload);
            console.log('Notification sent successfully:', response.data);
            console.log("RECIVER ID"+ userIds);
        } catch (error) {
            console.error('Failed to send notification:', error.response?.data || error.message);
        }
    };

    const sendTextMessage = () => {
        if (stompClient && message.trim()) {
            const currentMessage = message.trim();

            // Check if the message exceeds the word or character limit
            const wordCount = currentMessage.split(/\s+/).filter(Boolean).length; // Count words
            const charCount = currentMessage.length; // Count characters

            if (wordCount > 1000 || charCount > 14000) {
                alert(`Your message exceeds the limit of 1000 words or 14,000 characters. You have ${wordCount} words and ${charCount} characters.`);
                return; // Stop execution if the limits are exceeded
            }

            const msg = {
                chatId: `${userId}_${conversation.userId}`,
                senderId: String(userId),
                recipientId: String(conversation.userId),
                type: 'TEXT',
                content: message,
                timestamp: new Date().toISOString(),
                orderDetails: {
                    productId: orderDetails.productId,
                    productName: orderDetails.productName,
                    quantity: orderDetails.quantity,
                    deliveryDate: orderDetails.deliveryDate
                }
            };

            stompClient.send('/app/chat', {}, JSON.stringify(msg));

            if(isFormattedMessage){
                ReactGA.event({
                    category: 'Order',
                    action: 'OrderedItems',
                    label: orderDetails.productId,
                    value: orderDetails.quantity
                });
                ReactGA.event({
                    category: 'ShopOrder',
                    action: 'ShopOrders',
                    label: orderDetails.shopId,
                    value: orderDetails.quantity
                });
            }

            if (conversation?.prefilledMessage?.length > 2) {
                sendOrderPushNotification(String(conversation.userId));
            }else{
                sendPushNotification(String(conversation.userId));
            }

            // Clear message and orderDetails after sending
            setMessage('');
            setIsFormattedMessage(false);
            setOrderDetails({});
            onSaveDraft(conversation.userId, '');
        }
    };


    const sendFileMessage = async () => {
        if (file && userId) {
            const formData = new FormData();
            formData.append("file", file);

            setUploading(true); // Start uploading
            setUploadError(null); // Reset any previous errors

            try {
                const response = await authenticatedInstance.post(process.env.REACT_APP_CHAT_FILE_UPLOAD_PATH, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                });

                const fileMessage = {
                    // Optional, if you still need it for chat.
                    senderId: String(userId),
                    recipientId: String(conversation.userId),
                    type: 'FILE',
                    content: response.data.fileId, // Use fileId from the response
                    fileName: response.data.fileName,
                    fileType: response.data.fileType,
                    timestamp: new Date().toISOString()
                };

                if (stompClient) {
                    stompClient.send('/app/chat', {}, JSON.stringify(fileMessage));
                }

            } catch (error) {
                console.error('Error uploading file: ', error);
                if (error.response && error.response.data) {
                    setUploadError(error.response.data); // Set error message from backend
                } else {
                    setUploadError('Failed to upload file.'); // More generic error message
                }
            } finally {
                setUploading(false); // End uploading
                sendPushNotification(String(conversation.userId));
            }

            // Clear file
            setFile(null);
            setFilePreview(null);
        }
    };




    const handleFileUploadButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();  // Programmatically click the hidden file input
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Generate a preview URL
            const fileUrl = URL.createObjectURL(file);
            setFile(file);
            setFilePreview({ url: fileUrl, type: file.type });
        }
    };

    const handleFileCancel = () => {
        setFile(null);
        setFilePreview(null);
    };

    const openModal = (type, url) => {
        setModalContent({ type, url });
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };







    const handleWarningResponse = (proceed) => {
        setShowWarning(false);
        if (proceed) {
            // Remove link formatting and allow editing
            const plainText = message.replace(/\[([^\]]+)\]\([^)]+\)/g, '$1');
            setMessage(plainText);
            setIsFormattedMessage(false); // Indicate that the message is no longer formatted
        } else {
            // Revert to original message and keep formatting
            setMessage(messageBeforeEdit);
        }
    };

    const handleFormattedTextClick = (text, url) => {
        setEditText(text);
        setEditUrl(url);
        setEditModalOpen(true);
    };
    const handleDivInput = (e) => {
        const newValue = e.target.innerText;
        setMessage(newValue);
        onSaveDraft(conversation.userId, newValue);
        saveCaretPosition(e.target);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (e.shiftKey) {
                // Default creates new line
            } else if (!showWarning) {
                // Regular Enter -> Send message
                e.preventDefault();
                if (sendButtonRef.current) {
                    sendButtonRef.current.click();  // Trigger the send button
                }
            }
        }
    };



    const saveCaretPosition = (element) => {
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        element.savedCaret = range.cloneRange();
    };

    const restoreCaretPosition = (element) => {
        if (element.savedCaret) {
            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(element.savedCaret);
        }
    };



    const handleEditSave = () => {
        const updatedMessage = `[${editText}](${editUrl})`;
        setMessage(updatedMessage);
        setEditModalOpen(false);
    };

// Restore the cursor position
    const renderEditableMessageContent = (content) => {
        // Use the regex to find links in markdown format
        const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;

        // Replace markdown links with sanitized anchor tags
        const formattedContent = content.replace(linkRegex, (match, text, url) => {
            return `<a href="${sanitizeHtml(url)}" target="_blank" rel="noopener noreferrer" class="text-blue-500 underline hover:text-blue-700">${sanitizeHtml(text)}</a>`;
        });

        // Sanitize and return the entire formatted HTML
        return sanitizeHtml(formattedContent, {
            allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'span', 'br'],
            allowedAttributes: {
                a: ['href', 'target', 'rel', 'class'],
            },
        });
    };


    const containsLink = (message) => {
        const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
        return linkRegex.test(message);
    };

    const handleContentChange = (evt) => {
        const sanitizeConf = {
            allowedTags: ["b", "i", "a", "p"],
            allowedAttributes: { a: ["href"] }
        };
        const sanitizedContent = sanitizeHtml(evt.target.value, sanitizeConf);
        setMessage(sanitizedContent);
        onSaveDraft(conversation.userId, sanitizedContent);
    };
    const handlePaste = (event) => {
        event.preventDefault(); // Prevent the default paste behavior

        // Get the plain text data from the clipboard
        const paste = (event.clipboardData || window.clipboardData).getData('text');

        // Replace multiple new lines with single new lines (optional cleanup)
        const sanitizedText = paste.replace(/\n+/g, '\n').trim();

        // Insert the sanitized text at the caret position
        document.execCommand("insertText", false, sanitizedText);
    };
    const handleDragOver = (event) => {
        event.preventDefault(); // Prevent the default drag-over behavior
        event.stopPropagation();
    };
    const handleDrop = (event) => {
        event.preventDefault(); // Prevent the default drop behavior
        event.stopPropagation();

        const files = event.dataTransfer.files;  // Get the dropped files

        if (files && files.length > 0) {
            // If there is a file, pass it to the existing handleFileChange method
            handleFileChange({ target: { files } });
        }
    };


    return (
        <div className="chat-page flex flex-col h-full">
            {/* Chat Header */}
            <div className="chat-header flex items-center justify-between p-4 bg-white border-b shadow-md">
                {chatUser ? (
                    <div className="flex items-center">
                        {/*<img*/}
                        {/*    src={`${BASE_FILE_URL}/${chatUser.imageLink ? chatUser.imageLink : '/path/to/default-image.jpg'}`}*/}
                        {/*    alt={`${chatUser.fullName ? chatUser.fullName : 'Unknown user'}'s profile`}*/}
                        {/*    className="w-12 h-12 rounded-full"*/}
                        {/*/>*/}
                        {chatUser?.imageLink ? (
                            <Avatar className="w-12 h-12 rounded-full" alt={chatUser.fullName?.[0]}
                                    src={`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_FILE_DOWNLOAD_PATH}${chatUser?.imageLink}`}/>
                        ) : (
                            <Avatar className="w-12 h-12 rounded-full">{chatUser?.fullName?.[0]}</Avatar>
                        )}
                        <div className="ml-4">
                            <h2 className="font-bold text-lg">{chatUser.fullName ? chatUser.fullName : 'Unknown user'}</h2>
                            <span className="text-sm text-gray-500">
                            {onlineStatus.isOnline ? (
                                <span className="flex items-center">
                                🟢 Online
                              </span>
                            ) : (
                                <span className="flex items-center">
                                🔴 Offline | Last active: {new Date(onlineStatus.lastActivityTime + 'Z').toLocaleString()}
                              </span>
                            )}
                          </span>
                        </div>


                    </div>
                ) : (
                    <span>Loading user info...</span>
                )}
            </div>

            {/* Messages List */}
            <div className="flex-1 overflow-y-auto bg-gray-100 p-4">
                <MessageList
                    userId={userId}
                    conversation={conversation}
                    chatUser={chatUser}
                    currentUser={currentUser}
                />
            </div>

            {/* Input Area */}
            <div className="p-4 bg-white border-t shadow-inner">
                {isFormattedMessage ? (
                    // Prefilled message that is non-editable, with the ability to Send or Cancel
                    <div className="flex items-center space-x-4">
                        <div className="formatted-message-preview flex-1 text-gray-700">
                            <div
                                className="text-gray-700"
                                dangerouslySetInnerHTML={{ __html: renderEditableMessageContent(message) }}
                            />
                        </div>
                        <button
                            onClick={() => {
                                setMessage('');
                                setIsFormattedMessage(false);
                                setOrderDetails({});
                            }}
                            className="text-red-500 hover:text-red-700 p-2 transition-colors"
                        >
                            <FaTimes size={20} /> {/* Cancel Icon */}
                        </button>
                        <button
                            id="sendMessageButton"
                            onClick={sendMessage}
                            disabled={showWarning || uploading}
                            className={`p-2 rounded-lg transition-colors ${showWarning || uploading ? 'bg-gray-400 cursor-not-allowed' : 'text-blue-500 hover:text-blue-700'}`}
                        >
                            <FaPaperPlane size={20} /> {/* Send Icon */}
                        </button>

                        <input
                            type="file"
                            onChange={handleFileChange}
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                        />
                    </div>
                ) : (
                    // Editable ContentEditable component
                    <div className="flex items-center space-x-4">
                        <div className="flex-1 z-10">
                            <ContentEditable
                                html={message}
                                onChange={handleContentChange}
                                onKeyDown={handleKeyDown}
                                onPaste={handlePaste}
                                placeholder="Type a message..."
                                className="p-2 border rounded-lg shadow-sm max-h-32 overflow-y-auto"
                                style={{
                                    maxHeight: '8rem', // Limit height to 8rem (or adjust as needed)
                                    overflowY: 'auto', // Allow vertical scrolling if content exceeds height
                                }}
                                onDrop={handleDrop} // Attach the drop handler
                                onDragOver={handleDragOver} // Attach the drag-over handler
                            />
                        </div>
                        <button
                            id="sendMessageButton"
                            onClick={sendMessage}
                            disabled={showWarning || uploading}
                            className={`p-2 transition-colors ${showWarning || uploading ? 'bg-gray-400 cursor-not-allowed' : 'text-blue-500 hover:text-blue-700'}`}
                        >
                            <FaPaperPlane size={20} /> {/* Send Icon */}
                        </button>
                        <button
                            onClick={handleFileUploadButtonClick}
                            disabled={showWarning || uploading}
                            className={`p-2 transition-colors ${showWarning || uploading ? 'bg-gray-200 cursor-not-allowed' : 'text-gray-500 hover:text-gray-700'}`}
                        >
                            <FaPaperclip size={20} /> {/* Upload File Icon */}
                        </button>
                        <input
                            type="file"
                            onChange={handleFileChange}
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                        />
                    </div>
                )}
            </div>


            {/* File Preview */}
            {filePreview && (
                <div className="file-preview p-4 bg-gray-100 flex items-center justify-between">
                    {filePreview.type.startsWith('image/') ? (
                        <img
                            src={filePreview.url}
                            alt="Preview"
                            className="w-20 h-20 object-cover rounded-lg cursor-pointer"
                            onClick={() => openModal('image', filePreview.url)}
                        />
                    ) : filePreview.type.startsWith('video/') ? (
                        <video
                            src={filePreview.url}
                            controls
                            className="w-20 h-20 rounded-lg cursor-pointer"
                            onClick={() => openModal('video', filePreview.url)}
                        />
                    ) : (
                        <p>Selected file: {file.name}</p>
                    )}
                    <button
                        className="ml-4 text-red-500 hover:text-red-700"
                        onClick={handleFileCancel}
                    >
                        Cancel File
                    </button>
                </div>
            )}

            {/* Modals for Preview and Edit */}
            <ReactModal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="File Modal"
                className="modal"
                overlayClassName="modal-overlay"
            >
                {modalContent.type === 'image' ? (
                    <img src={modalContent.url} alt="Modal content" className="modal-content"/>
                ) : modalContent.type === 'video' ? (
                    <video src={modalContent.url} controls className="modal-content"/>
                ) : null}
                <button onClick={closeModal} className="close-modal">Close</button>
            </ReactModal>

            {showWarning && (
                <div className="warning-popup p-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700">
                    <p>Editing this message will remove the links. Do you want to proceed?</p>
                    <div className="mt-2 flex space-x-4">
                        <button onClick={() => handleWarningResponse(true)}
                                className="text-green-700 hover:text-green-900">
                            Proceed
                        </button>
                        <button onClick={() => handleWarningResponse(false)}
                                className="text-red-700 hover:text-red-900">
                            Cancel
                        </button>
                    </div>
                </div>
            )}

            <ReactModal
                isOpen={editModalOpen}
                onRequestClose={() => setEditModalOpen(false)}
                contentLabel="Edit Link"
                className="modal"
                overlayClassName="modal-overlay"
            >
                <div className="edit-link-form p-4">
                    <label className="block mb-2">
                        Text:
                        <input
                            type="text"
                            value={editText}
                            onChange={(e) => setEditText(e.target.value)}
                            className="w-full p-2 border rounded-lg mt-1"
                        />
                    </label>
                    <label className="block mb-4">
                        URL:
                        <input
                            type="text"
                            value={editUrl}
                            onChange={(e) => setEditUrl(e.target.value)}
                            className="w-full p-2 border rounded-lg mt-1"
                        />
                    </label>
                    <div className="flex space-x-4">
                        <button onClick={handleEditSave}
                                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">
                            Save
                        </button>
                        <button onClick={() => setEditModalOpen(false)}
                                className="px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400">
                            Cancel
                        </button>
                    </div>
                </div>
            </ReactModal>
        </div>
    );

};

export default ChatPage;
